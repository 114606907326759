/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Taviraj_100Thin = require('./Taviraj_100Thin.ttf');
export const Taviraj_100Thin_Italic = require('./Taviraj_100Thin_Italic.ttf');
export const Taviraj_200ExtraLight = require('./Taviraj_200ExtraLight.ttf');
export const Taviraj_200ExtraLight_Italic = require('./Taviraj_200ExtraLight_Italic.ttf');
export const Taviraj_300Light = require('./Taviraj_300Light.ttf');
export const Taviraj_300Light_Italic = require('./Taviraj_300Light_Italic.ttf');
export const Taviraj_400Regular = require('./Taviraj_400Regular.ttf');
export const Taviraj_400Regular_Italic = require('./Taviraj_400Regular_Italic.ttf');
export const Taviraj_500Medium = require('./Taviraj_500Medium.ttf');
export const Taviraj_500Medium_Italic = require('./Taviraj_500Medium_Italic.ttf');
export const Taviraj_600SemiBold = require('./Taviraj_600SemiBold.ttf');
export const Taviraj_600SemiBold_Italic = require('./Taviraj_600SemiBold_Italic.ttf');
export const Taviraj_700Bold = require('./Taviraj_700Bold.ttf');
export const Taviraj_700Bold_Italic = require('./Taviraj_700Bold_Italic.ttf');
export const Taviraj_800ExtraBold = require('./Taviraj_800ExtraBold.ttf');
export const Taviraj_800ExtraBold_Italic = require('./Taviraj_800ExtraBold_Italic.ttf');
export const Taviraj_900Black = require('./Taviraj_900Black.ttf');
export const Taviraj_900Black_Italic = require('./Taviraj_900Black_Italic.ttf');
